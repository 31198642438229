import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select } from "@material-ui/core";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, jaJP } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { useContext } from "react";
import { Export_csv } from "../classes/export_csv_class";
import GenericTemplate from "../templates/GenericTemplate";
import * as commonFnc from '../common/commonFnc';
import * as BL_exportCsv from '../BL/BL_exportCsv';
import '../css/Loading.css';
import {GlobalContext} from '../globalState'
import { Button } from "@mui/material";

// 1: HH.mm表示
// 2: 合計から15分切り上げ
// 3: 合計から15分切り下げ

const DIVISOR = 1000 * 60 * 60;


// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport 
//         csvOptions={{
//           fileName: 'mamereco',
//           utf8WithBom: true
//         }}
//         localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
//         />
//     </GridToolbarContainer>
//   );
// }

// カスタムツールバーコンポーネント
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton/>
      <GridToolbarColumnsButton/>
      <GridToolbarExport
        csvOptions={{
          fileName: 'mamereco',
          utf8WithBom: true,  // BOMを付加して文字化けを防ぐ
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}/>
      <GridToolbarQuickFilter/>

    </GridToolbarContainer>
  );
};

const ExportCsvPage = (props) =>{
    const {
      setLogin_user_id
      , setKintai_bscs
      , Login_user_info
      , setDisplay_user_info
      , Shift_msts
      , setShift_msts
      , Users
      , Department_msts
    } = props;

    const [Export_csv_msts, setExport_csv_msts] = useState([]);

    const shift_group_cd_hash = useContext(GlobalContext).shift_group_cd;
    const shift_type_cd_hash = useContext(GlobalContext).shift_type_cd;
    const Env_msts = useContext(GlobalContext).Env_msts;
    const approvalTexts = useContext(GlobalContext).approvalTexts;

    const CSV_CONVERT_STYLE_CD = Env_msts.find((p)=>p.env_key === "CSV_CONVERT_STYLE_CD")?.env_value;
    const CSV_UNIT_VALUE = Env_msts.find((p)=>p.env_key === "CSV_UNIT_VALUE")?.env_value;
    const COUNT_REAL_WORK_DAYS_AKE = Env_msts.find((p)=>p.env_key === "COUNT_REAL_WORK_DAYS_AKE")?.env_value;
    const DEFAULT_EXPORT_CSV_SORT_KEY = Env_msts.find((p)=>p.env_key === "DEFAULT_EXPORT_CSV_SORT_KEY")?.env_value;
    const UNIT = 1000 * 60 * (CSV_UNIT_VALUE?CSV_UNIT_VALUE:30);

    const countShiftList = Export_csv_msts.filter(p=>p.shift_count_flg===true);

    const [searchYear, setSearchYear] =useState((new Date()).getFullYear());
    const [searchMonth, setSearchMonth] =useState((new Date()).getMonth());

    const [serchApplovalStatusCd, setSerchApplovalStatusCd] = useState({
      checked90: false,
      checked80: false,
      checked30: false,
      checked20: false,
      checked10: false,
    })
    const [checkedValues, setCheckedValues] = useState([]);
    const [exportNameFlg, setExportNameFlg] = useState(false);
    const [sortKey, setSortKey] = useState(DEFAULT_EXPORT_CSV_SORT_KEY); // user_id or department_cd　or csv_seq

    if(Shift_msts === null){
      fetch("/api/select/shift_mst")
        .then((res) => res.json())
        .then((data) => setShift_msts(data))
    }


    // CSV出力マスタ一覧取得
    useEffect(()=>{
      fetch("/api/select/export_csv_msts")
        .then((res) => res.json())
        .then((data) => setExport_csv_msts(data))
    },[]);

    const [allUser_Kintai_bscs, setAllUser_Kintai_bscs] = useState([]);
    useEffect(async ()=>{
      const query = new URLSearchParams({target_date_from: commonFnc.formatDate(new Date(searchYear, searchMonth)), target_date_to: commonFnc.formatDate(new Date(searchYear,searchMonth+1,0))});
      await fetch(`/api/select/allUser_term_kintai_bsc?${query}`)
        .then((res) => res.json())
        .then((data) => setAllUser_Kintai_bscs(data));
      
    },[searchYear,searchMonth,checkedValues]);


    const handleChangeAppcdCheckBox = (e) =>{
      // チェック状態を管理
      setSerchApplovalStatusCd({ ...serchApplovalStatusCd, [e.target.name]: e.target.checked });

      // 検索対象の配列作成
      if (checkedValues.includes(e.target.value)) {
        setCheckedValues(
          checkedValues.filter((checkedValue) => checkedValue !== e.target.value)
        );
      } else {
        setCheckedValues([...checkedValues, e.target.value]);
      }
      
    }
  
    const msecFloorToHour = (msec, unit, div) =>{
      return msec ? Math.floor(msec/unit)*unit/div : 0
    }
    const msecCeilToHour = (msec, unit, div) =>{
      return msec ? Math.ceil(msec/unit)*unit/div : 0
    }

    // 汎用カウンタ用のCSV表示情報を作成する。
    const createViewCounterItem = (export_csv_mst) =>{

      const countIndexStr = (export_csv_mst.column_name).replace("env_", ""); // ex) count1, count2, ...
      const counter = Env_msts.find(p => p.env_key === (countIndexStr.toUpperCase()));
      if(counter){
        let column_item = {
          field: countIndexStr,
          headerName: counter?.env_value,
          hide :export_csv_mst.log_del_flg || counter.log_del_flg,
          align: 'center', 
        }
        return column_item;

      }else{
        return null;
      }

    }

    // console.log(allUser_Kintai_bscs)
    const data = allUser_Kintai_bscs
      .filter(item => 
        (new Date(item.date)).getFullYear()===parseInt(searchYear) 
        && (new Date(item.date)).getMonth()===parseInt(searchMonth) 
        && checkedValues.find(p=>p===item.approval_status_cd))
      .reduce((result, current) => {
        const user = Users.find((p) => p.user_id === current.user_id);

        // userが取得できなければスキップ
        if(user && user?.user_id && user?.if_cd){

          const shift = Shift_msts.find((p) => p.shift_type_cd === current.shift_type_cd);
          const timePaidHolidayShift = Shift_msts.find((p) => p.shift_type_cd === current.paid_holiday_shift_type_cd);
          const element = result.find((p) => p.user_id === user.if_cd);
          
          // const target_first = BL_exportCsv.getCountItem(Shift_msts,current.shift_type_cd);
          // const target_second = BL_exportCsv.getCountItem(Shift_msts,current.harf_holiday_shift_type_cd);

          if(shift){
            
            if (element) {
              // ループ2回目以降

              element.addWork_time(current.work_time ? current.work_time : 0);
              element.addMidnight_work_time(current.midnight_work_time ? current.midnight_work_time : 0);
              element.addOver_time(current.over_time ? current.over_time : 0);
              element.addMidnight_over_time(current.midnight_over_time ? current.midnight_over_time : 0)
              element.addRest_time(current.rest_time ? current.rest_time : 0);
              element.addEarly_time(current.early_time ? current.early_time : 0);
              element.addBehind_time(current.behind_time ? current.behind_time : 0);
              element.addEarly_behind_time((current.early_time ? current.early_time : 0) + (current.behind_time ? current.behind_time : 0));
              element.addPublic_holiday_time(current.public_holiday_time ? current.public_holiday_time : 0);
              element.addPaid_holiday_time(current.paid_holiday_time ? current.paid_holiday_time : 0);
              
              element.addtime_paid_holiday_time(timePaidHolidayShift ? timePaidHolidayShift.paid_holiday_time : 0); // 時間有給時間数
              
              // 勤務日数
              element.work_days += BL_exportCsv.GetWorkDays(shift);

              // 欠勤日数
              if(shift.shift_group_cd === shift_group_cd_hash["SICK_LEAVE_GROUP_CD"]){
                element.absence_days += 1;
              }else if(shift.shift_group_cd === shift_group_cd_hash["HARF_SICK_LEAVE_GROUP_CD"]){
                element.absence_days += 0.5;
              }

              // 夜勤日数
              if(shift.shift_group_cd === shift_group_cd_hash["NIGHT_SHIFT_GROUP_CD"]){
                element.incrementNight_work_times();
              }

              // 宿直日数
              if(shift.shift_group_cd === shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"] 
                || shift.shift_group_cd === shift_group_cd_hash["OVER_NIGHT_WATCH_SHIFT_GROUP_CD"] 
                || shift.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
                element.incrementNight_watch_work_times();
              }

              // 勤務実日数
              if((shift.shift_group_cd 
                && shift.shift_group_cd !== shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"] 
                && shift.shift_group_cd !== shift_group_cd_hash["SICK_LEAVE_GROUP_CD"]) 
                && shift.shift_group_cd !== shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"]){

                // COUNT_REAL_WORK_DAYS_AKE=0の時は明けは実日数にカウントしない。
                if(!(shift.shift_type_cd === shift_type_cd_hash["AKE_CD"] && COUNT_REAL_WORK_DAYS_AKE==="0")){
                  element.incrementReal_Work_days();
                }
              }

              // 休日出勤 休日シフトかつ勤務、残業、深夜残業のいずれかに数値が存在する場合。
              if(shift.shift_group_cd 
                && shift.shift_group_cd === shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"] 
                && (current.work_time + current.over_time + current.midnight_over_time) > 0){
                element.incrementHoliday_work_days();
                element.addHoliday_work_time(current.work_time ? current.work_time : 0);
                element.addHoliday_over_time(current.over_time ? current.over_time : 0);
                element.addHoliday_midnight_over_time(current.midnight_over_time ? current.midnight_over_time : 0);
              }

              if(current.count1){element.incrementCount1()}
              if(current.count2){element.incrementCount2()}
              if(current.count3){element.incrementCount3()}
              if(current.count4){element.incrementCount4()}
              if(current.count5){element.incrementCount5()}

              // シフトタイプごとのカウント
              countShiftList.forEach((countShift)=>{
                const shiftCountColumnName = "count_" + countShift.shift_type_cd;
                if(current.shift_type_cd === countShift.shift_type_cd){
                  element[shiftCountColumnName] += 1;
                }
              });


            } else {
              // ループ1回目

              let user_name = "";
              let department_cd = "";
              let user_note1 = "";

              if(user){
                user_name = user.first_name + " " + user.second_name;
                user_note1 = user.notes;
                let department = Department_msts.find((p) => p.department_cd === user.department_cd);
                if(department){department_cd = department.if_cd};
              }

              let exportCsvView = new Export_csv();
              exportCsvView.csv_id = 0
              exportCsvView.user_id = user.if_cd;
              exportCsvView.department_cd = department_cd;
              exportCsvView.user_name = user_name;
              exportCsvView.user_note1 = user_note1;

              exportCsvView.work_time = current.work_time ? current.work_time : 0; // 勤務時間数
              exportCsvView.midnight_work_time = current.midnight_work_time ? current.midnight_work_time : 0; // 深夜勤務時間数
              exportCsvView.over_time = current.over_time ? current.over_time : 0; // 残業時間数
              exportCsvView.midnight_over_time = current.midnight_over_time ? current.midnight_over_time : 0; // 深夜残業時間数
              exportCsvView.rest_time = current.rest_time ? current.rest_time : 0; // 休憩時間
              exportCsvView.early_time = current.early_time ? current.early_time : 0; // 早退時間
              exportCsvView.behind_time = current.behind_time ? current.behind_time : 0; // 遅刻時間
              exportCsvView.early_behind_time = (current.early_time ? current.early_time : 0) + (current.behind_time ? current.behind_time : 0); // 遅刻早退時間
              exportCsvView.public_holiday_time = current.public_holiday_time ? current.public_holiday_time : 0; // 公休時間
              exportCsvView.paid_holiday_time = current.paid_holiday_time ? current.paid_holiday_time : 0; // 有給時間

              exportCsvView.time_paid_holiday_time = timePaidHolidayShift ? timePaidHolidayShift.paid_holiday_time : 0; // 時間有給時間数

              // 勤務日数
              exportCsvView.work_days = BL_exportCsv.GetWorkDays(shift);

              // 欠勤日数
              if(shift.shift_group_cd === shift_group_cd_hash["SICK_LEAVE_GROUP_CD"]){
                exportCsvView.absence_days = 1;
              }else if(shift.shift_group_cd === shift_group_cd_hash["HARF_SICK_LEAVE_GROUP_CD"]){
                exportCsvView.absence_days = 0.5;
              }

              // 夜勤日数
              if(shift.shift_group_cd === shift_group_cd_hash["NIGHT_SHIFT_GROUP_CD"]){
                exportCsvView.incrementNight_work_times();
              }

              // 宿直日数
              if(shift.shift_group_cd === shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"] 
                || shift.shift_group_cd === shift_group_cd_hash["OVER_NIGHT_WATCH_SHIFT_GROUP_CD"] 
                || shift.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
                exportCsvView.incrementNight_watch_work_times();
              }

              // 勤務実日数
              if(shift.shift_group_cd 
                && shift.shift_group_cd !== shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"] 
                && shift.shift_group_cd !== shift_group_cd_hash["SICK_LEAVE_GROUP_CD"] 
                && shift.shift_group_cd !== shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"]){

                  // console.log(shift.shift_type_cd)
                  // console.log(shift_type_cd_hash["AKE_CD"])
                  // console.log(COUNT_REAL_WORK_DAYS_AKE)
                  // COUNT_REAL_WORK_DAYS_AKE=0の時は明けは実日数にカウントしない。
                  if(!(shift.shift_type_cd === shift_type_cd_hash["AKE_CD"] && COUNT_REAL_WORK_DAYS_AKE==="0")){
                    exportCsvView.incrementReal_Work_days();
                  }
              }
              
              // 休日出勤 休日シフトかつ勤務、残業、深夜残業のいずれかに数値が存在する場合。
              if(shift.shift_group_cd 
                && shift.shift_group_cd === shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"] 
                && (current.work_time + current.over_time + current.midnight_over_time) > 0){
                exportCsvView.incrementHoliday_work_days();
                exportCsvView.holiday_work_time = current.work_time ? current.work_time : 0;
                exportCsvView.holiday_over_time = current.over_time ? current.over_time : 0;
                exportCsvView.holiday_midnight_over_time = current.midnight_over_time ? current.midnight_over_time : 0;
              }
              
              exportCsvView.count1 = current.count1? 1 : 0;
              exportCsvView.count2 = current.count2? 1 : 0;
              exportCsvView.count3 = current.count3? 1 : 0;
              exportCsvView.count4 = current.count4? 1 : 0;
              exportCsvView.count5 = current.count5? 1 : 0;

              // シフトタイプごとのカウント
              countShiftList.forEach((countShift)=>{
                const shiftCountColumnName = "count_" + countShift.shift_type_cd;
                exportCsvView[shiftCountColumnName] = shift.shift_type_cd === countShift.shift_type_cd ? 1 : 0;
              });

              result.push(exportCsvView); 
            }
          }
        }
        return result;
      }, []);

      data.forEach(item => {

        // みなし残業算出処理　あとで関数にする
        // 残業時間数＞=みなし残業時間　→　残業時間=残業時間 - みなし残業時間
        // 残業時間数＜みなし残業時間　→　みなし残業時間=残業時間 　残業時間=0
        const user = Users.find((p) => p.if_cd === item.user_id);

        // userが取得できなければスキップ
        if(!user) return;
        
        // 固定残業時間
        var fixed_over_time_msec = user?.fixed_overtime ? user.fixed_overtime * 60 * 60 * 1000 : 0;

        // CSV並び順
        item.csv_seq = user?.csv_seq ? user?.csv_seq : "";
        
        if( item.over_time >= fixed_over_time_msec){
          item.over_time = item.over_time - fixed_over_time_msec;
        }else{
          item.fixed_over_time = fixed_over_time_msec - item.over_time;
          item.over_time = 0;
        }


        // 時間有給以外の有給時間数
        item.day_paid_holiday_time = item.paid_holiday_time - item.time_paid_holiday_time;

        if(CSV_CONVERT_STYLE_CD === "1"){
          // 時間表示　HH:mm
          item.work_time = commonFnc.getHHmm(item.work_time);
          item.over_time = commonFnc.getHHmm(item.over_time);
          item.midnight_work_time = commonFnc.getHHmm(item.midnight_work_time);
          item.midnight_over_time = commonFnc.getHHmm(item.midnight_over_time);
          item.rest_time = commonFnc.getHHmm(item.rest_time);
          item.early_time = commonFnc.getHHmm(item.early_time);
          item.behind_time = commonFnc.getHHmm(item.behind_time);
          item.early_behind_time = commonFnc.getHHmm(item.early_behind_time);
          item.public_holiday_days = item.public_holiday_time;
          item.paid_holiday_days = item.paid_holiday_time;
          item.fixed_over_time = commonFnc.getHHmm(fixed_over_time_msec);
          item.holiday_work_time = commonFnc.getHHmm(item.holiday_work_time);
          item.holiday_over_time = commonFnc.getHHmm(item.holiday_over_time);
          item.holiday_midnight_over_time = commonFnc.getHHmm(item.holiday_midnight_over_time);
          item.time_paid_holiday_time = commonFnc.getHHmm(item.time_paid_holiday_time);
          item.day_paid_holiday_time = commonFnc.getHHmm(item.day_paid_holiday_time);
          
        }else if(CSV_CONVERT_STYLE_CD === "2"){
          item.work_time = msecCeilToHour(item.work_time, UNIT, DIVISOR);
          item.over_time = msecCeilToHour(item.over_time, UNIT, DIVISOR);
          item.midnight_work_time = msecCeilToHour(item.midnight_work_time, UNIT, DIVISOR);
          item.midnight_over_time = msecCeilToHour(item.midnight_over_time, UNIT, DIVISOR);
          item.rest_time = msecCeilToHour(item.rest_time, UNIT, DIVISOR);
          item.early_time = msecCeilToHour(item.early_time, UNIT, DIVISOR);
          item.behind_time = msecCeilToHour(item.behind_time, UNIT, DIVISOR);
          item.early_behind_time = msecCeilToHour(item.early_behind_time, UNIT, DIVISOR);
          item.public_holiday_days = msecCeilToHour(item.public_holiday_time, 1, 8);
          item.paid_holiday_days = msecCeilToHour(item.paid_holiday_time, 1, 8);
          item.fixed_over_time = msecCeilToHour(fixed_over_time_msec, UNIT, DIVISOR);
          item.holiday_work_time = msecCeilToHour(item.holiday_work_time, UNIT, DIVISOR);
          item.holiday_over_time = msecCeilToHour(item.holiday_over_time, UNIT, DIVISOR);
          item.holiday_midnight_over_time = msecCeilToHour(item.holiday_midnight_over_time, UNIT, DIVISOR);
          // item.time_paid_holiday_time = msecCeilToHour(item.time_paid_holiday_time, 1, 8);
          // item.day_paid_holiday_time = msecCeilToHour(item.day_paid_holiday_time, 1, 8);
        }else if(CSV_CONVERT_STYLE_CD === "3"){
          item.work_time = msecFloorToHour(item.work_time, UNIT, DIVISOR);
          item.over_time = msecFloorToHour(item.over_time, UNIT, DIVISOR);
          item.midnight_work_time = msecFloorToHour(item.midnight_work_time, UNIT, DIVISOR);
          item.midnight_over_time = msecFloorToHour(item.midnight_over_time, UNIT, DIVISOR);
          item.rest_time = msecFloorToHour(item.rest_time, UNIT, DIVISOR);
          item.early_time = msecFloorToHour(item.early_time, UNIT, DIVISOR);
          item.behind_time = msecFloorToHour(item.behind_time, UNIT, DIVISOR);
          item.early_behind_time = msecFloorToHour(item.early_behind_time, UNIT, DIVISOR);
          item.public_holiday_days = msecFloorToHour(item.public_holiday_time, 1, 8);
          item.paid_holiday_days = msecFloorToHour(item.paid_holiday_time, 1, 8);
          item.fixed_over_time = msecFloorToHour(fixed_over_time_msec, UNIT, DIVISOR);
          item.holiday_work_time = msecFloorToHour(item.holiday_work_time, UNIT, DIVISOR);
          item.holiday_over_time = msecFloorToHour(item.holiday_over_time, UNIT, DIVISOR);
          item.holiday_midnight_over_time = msecFloorToHour(item.holiday_midnight_over_time, UNIT, DIVISOR);
          // item.time_paid_holiday_time = msecFloorToHour(item.time_paid_holiday_time, 1, 8);
          // item.day_paid_holiday_time = msecFloorToHour(item.day_paid_holiday_time, 1, 8);
        }
      });
      
      let columns = [];
      Export_csv_msts.sort((f, s) => f.seq - s.seq).forEach((export_csv_mst) => {

        if(export_csv_mst.column_name.match(/env_count/)){
          // 汎用カウンタ用の項目
          const counter_item = createViewCounterItem(export_csv_mst);
          if(counter_item){
            columns.push(counter_item);
          }

        }else{
          // 通常の項目
          columns.push({
            field: export_csv_mst.column_name,
            headerName: exportNameFlg?export_csv_mst.export_name:export_csv_mst.view_name,
            hide :export_csv_mst.log_del_flg,
            align: 'center', 
          });

        }
      })


    const style = {
      optionButton:{
        margin:'5px 30px 5px 10px',
      },
    } 
    return (
        <GenericTemplate title="CSVデータ出力"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!Login_user_info? <div class="loader">Loading...</div> :  
          <div>
            <Select
              onChange={e => setSearchYear(e.target.value)}
              defaultValue={searchYear}
              name="searchYear">
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
            </Select>
            <Select
              onChange={e => setSearchMonth(e.target.value)}
              defaultValue={searchMonth}
              name="searchMonth">
                <MenuItem value="0">1</MenuItem>
                <MenuItem value="1">2</MenuItem>
                <MenuItem value="2">3</MenuItem>
                <MenuItem value="3">4</MenuItem>
                <MenuItem value="4">5</MenuItem>
                <MenuItem value="5">6</MenuItem>
                <MenuItem value="6">7</MenuItem>
                <MenuItem value="7">8</MenuItem>
                <MenuItem value="8">9</MenuItem>
                <MenuItem value="9">10</MenuItem>
                <MenuItem value="10">11</MenuItem>
                <MenuItem value="11">12</MenuItem>
            </Select>
            <FormControl component="fieldset" style={{"margin-left":"30px"}}>
              <FormGroup row aria-label="gender" name="applovalStatusCdGroup">
                <FormControlLabel control={<Checkbox checked={serchApplovalStatusCd.checked90} onChange={handleChangeAppcdCheckBox} name="checked90" value="90" />} label={approvalTexts["90"]}/>
                <FormControlLabel control={<Checkbox checked={serchApplovalStatusCd.checked80} onChange={handleChangeAppcdCheckBox} name="checked80" value="80" />} label={approvalTexts["80"]}/>
                <FormControlLabel control={<Checkbox checked={serchApplovalStatusCd.checked30} onChange={handleChangeAppcdCheckBox} name="checked30" value="30" />} label={approvalTexts["30"]}/>
                <FormControlLabel control={<Checkbox checked={serchApplovalStatusCd.checked20} onChange={handleChangeAppcdCheckBox} name="checked20" value="20" />} label={approvalTexts["20"]}/>
                <FormControlLabel control={<Checkbox checked={serchApplovalStatusCd.checked10} onChange={handleChangeAppcdCheckBox} name="checked10" value="10" />} label={approvalTexts["10"]}/>
              </FormGroup>
            </FormControl>
            <Button
              variant="outlined"
              onClick={()=>setExportNameFlg(!exportNameFlg)}
              sx={style.optionButton}
            >
              {exportNameFlg?'表示名称':'出力名称'}
            </Button>
            {/* <Button
              variant="outlined"
              onClick={()=>setSortKey(sortKey==='user_id'?'department_cd':'user_id')}
              sx={style.optionButton}
            >
              {sortKey==='user_id'?'職員番号順':'部署順'}
            </Button> */}
            <Select
              onChange={e => setSortKey(e.target.value)}
              defaultValue={sortKey}
              name="csvSortType">
                <MenuItem value="user_id">職員番号順</MenuItem>
                <MenuItem value="department_cd">部署順</MenuItem>
                <MenuItem value="csv_seq">CSV出力順</MenuItem>
            </Select>
            <div style={{ height: "70vh", width: '100%' }}>
              <DataGrid
                  rows={data.sort((f, s) => f[sortKey].localeCompare(s[sortKey]))}
                  columns={columns}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  autoPageSize={true}
                  autoWidth={true}
                  density={"compact"}
                  checkboxSelection
                  disableSelectionOnClick
                  getRowId={(data) => data.user_id}
                  components={{
                    Toolbar: CustomToolbar,// ツールバーを指定する
                  }}
                  localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              />
            </div>
          </div>
        }
        </GenericTemplate>
    );
}

export default ExportCsvPage;